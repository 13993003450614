import AccentButton from '@centered/components/dist/AccentButton'
import { db } from '@centered/firebase'
import { highlight, languages } from 'prismjs'
import React, { useEffect, useState } from 'react'
import Editor from 'react-simple-code-editor'

export default function Home(): React.ReactNode {
  const [value, setValue] = useState<string[]>([])

  useEffect(() => {
    db.doc(`website/quotes`)
      .get()
      .then((doc) => {
        const data = doc.data() as {
          quotes: string[]
        }

        if (data) {
          setValue(data.quotes)
        }
      })
  }, [])

  return (
    <div className="w-96 p-10">
      <h1>Quote Links</h1>

      <Editor
        value={(value ?? []).join('\n')}
        highlight={(code) => highlight(code, languages.js, 'js')}
        onValueChange={(code) => {
          setValue(code.split('\n'))
        }}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
        }}
        className="bg-white flex-1 flex-shrink-0 relative border"
        padding={10}
      />

      <div className="mt-4">
        <AccentButton
          onClick={() => {
            db.doc(`website/quotes`)
              .set(
                {
                  quotes: value,
                },
                {
                  merge: true,
                },
              )
              .then(() => {
                alert('Saved')
              })
              .catch(() => {
                alert('Error')
              })
          }}
        >
          Save
        </AccentButton>
      </div>
    </div>
  )
}

Home.displayName = 'Home'
